(function ($) {
    const $header = $('.site-header');
    $('a[href*="#"]')
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function (event) {
            $header_height = $header.outerHeight();
            if (
                location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
                location.hostname == this.hostname
            ) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    event.preventDefault();
                    $('html, body').animate({
                        scrollTop: target.offset().top - $header_height
                    }, 1000, function () {
                        var $target = $(target);
                        if ($target.is(":focus")) { // Checking if the target was focused
                            return false;
                        }
                    });
                }
            }
        });
})(jQuery);