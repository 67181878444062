import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';


(function ($, window, document, undefined) {


	let $body = $('body,html');
	let body_class = 'menu-is-open';

	//---------------------------------------------------
	// Mobile menu toggle

	let $toggle = $('.menu-toggle');

	$toggle.attr('aria-expanded', 'false');

	$toggle.on('click', (e) => {
		e.preventDefault();
		toggle_menu();
	});

	function open_menu() {
		$toggle.attr('aria-expanded', 'true');
		$toggle.addClass('is-active');
		$body.addClass(body_class);

		disableBodyScroll(document.querySelector('#header-menu'), {
			reserveScrollBarGap: true
		});
		$(document).on('keydown.close_menu', (event) => {
			if ('Escape' !== event.key) {
				return;
			}
			close_menu();
		});
	}

	function close_menu() {
		$toggle.attr('aria-expanded', 'false');
		$toggle.removeClass('is-active');
		$body.removeClass(body_class);
		enableBodyScroll(document.querySelector('#header-menu'));
		$(document).off('.close_menu');
	}

	//---------------------------------------------------
	//Dropdown menu toggle

	let $dropdown_toggle = $('.menu-item-has-children');

	$dropdown_toggle.attr('aria-expanded', 'false');

	$dropdown_toggle.on('click', (e) => {

		let $target = $(e.target);
		if (!$target.is('.menu-item-has-children')) {
			$target = $target.closest('.menu-item-has-children');
		}

		$($dropdown_toggle).not($target).removeClass('open').attr('aria-expanded', 'false');

		if ($($target).hasClass('open')) {
			$($target).attr('aria-expanded', 'false');
		} else {
			$($target).attr('aria-expanded', 'true');
		}

		$($target).toggleClass('open');
	});

	function toggle_menu() {
		if ($body.hasClass(body_class)) {
			close_menu();
		} else {
			open_menu();
		}
	}

})(jQuery, window, document);