import Iconify from '@iconify/iconify';
import 'default-passive-events';
import {
  gsap
} from "gsap";
import {
  ScrollTrigger
} from "gsap/ScrollTrigger";

import './scripts/smooth-scroll';
import './scripts/header-controller';
import {
  slick
} from "slick-carousel";

gsap.registerPlugin(ScrollTrigger);

gsap.defaults({
  duration: 0.5
});

ScrollTrigger.config({
  limitCallbacks: true
})

$(document).ready(() => {

  //-- Gsap Animations --

  ScrollTrigger.batch('.animated', {
    once: true,
    onEnter: batch => {
      batch.forEach((item, index) => {
        gsap.to(item.children, {
          y: 0,
          stagger: 0.05,
          delay: index * 0.1,
          autoAlpha: 1,
          //clearProps: "transform"
        })
      })
    },
    onEnterBack: batch => {
      batch.forEach((item, index) => {
        gsap.to(item.children, {
          autoAlpha: 1,
          stagger: 0.05
        })
      })
    },
  });
})



//----------------------------------------------------------------

jQuery('.intro-questions').slick({
  autoplay: true,
  autoplaySpeed: 2000,
  fade: true,
  swipe: false,
  touchMove: false,
  speed: 1500,
  draggable: false,
  pauseOnHover: false,
  infinite: true,
  cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
  dots: false,
  arrows: false,

});


jQuery('.recent-posts-slider').slick({
  autoplay: false,
  pauseOnHover: false,
  infinite: false,
  dots: false,
  arrows: false,
  swipeToSlide: true,
  slidesToScroll: 1,
  slidesToShow: 3,

  responsive: [{
      breakpoint: 1351,
      settings: {
        slidesToShow: 2,
        arrows: true,
        infinite: true,
        prevArrow: '<button type="button" aria-label="Prev" class="slick-prev"><span class="iconify" data-icon="akar-icons:circle-chevron-left-fill"></span></button>',
        nextArrow: '<button type="button" aria-label="Next" class="slick-next"><span class="iconify" data-icon="akar-icons:circle-chevron-right-fill"></span></button>',
        appendArrows: jQuery('.recent-posts-slider-arrows'),
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        arrows: true,
        draggable: true,
        swipe: true,
        touchMove: true,
        infinite: true,
        prevArrow: '<button type="button" aria-label="Prev" class="slick-prev"><span class="iconify" data-icon="akar-icons:circle-chevron-left-fill"></span></button>',
        nextArrow: '<button type="button" aria-label="Next" class="slick-next"><span class="iconify" data-icon="akar-icons:circle-chevron-right-fill"></span></button>',
        appendArrows: jQuery('.recent-posts-slider-arrows'),
      }
    }
  ]
});



// Handler that uses various data-* attributes to trigger
// specific actions, mimicing bootstraps attributes
const triggers = Array.from(document.querySelectorAll('#anaptiksiaka-stadia .card-header'));

window.addEventListener('click', (ev) => {

  var $elm = jQuery(ev.target);

  if (!$elm.is('.card-header')) {
    $elm = $elm.closest('.card-header');

  }
  $elm.toggleClass('collapsed');

  const selector = $elm.attr('data-target');
  collapse(selector, 'toggle');


}, false);


const fnmap = {
  'toggle': 'toggle',
  'show': 'add',
  'hide': 'remove'
};
const collapse = (selector, cmd) => {
  const targets = Array.from(document.querySelectorAll(selector));
  targets.forEach(target => {
    target.classList[fnmap[cmd]]('show');
  });
  setTimeout(() => {
    ScrollTrigger.refresh();
  }, 200)

}

if(jQuery('.js-open-cookie-list').length){
  jQuery('.js-open-cookie-list').click(openCookieHubList);

  function openCookieHubList() {
    if (!window.cookiehub) {
      return;
    }
  
    window.cookiehub.openSettings();
    document.querySelector('[href="#ch2-declaration"]').click();
  }
  
}

